export interface IPortalSettings {
    admin_email: string;
    admin_first_name: string;
    admin_last_name: string;
    color: string;
    company_address: string;
    company_city_id: string;
    company_name: string;
    company_vat: string;
    company_phone_number: string;
    company_zip: string;
    domain: string;
    leasing_settings: Array<any>;
    logo: string;
    name: string;
    status: any;
    status_id: number;
}

export enum PortalType {
    mpp = 'Mitarbeiter PC Programm',
    firmenroller = 'Firmenroller',
}
